import React, { useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldRussiaLow from "@amcharts/amcharts5-geodata/worldRussiaLow";
import am5geodata_lang_RU from "@amcharts/amcharts5/locales/ru_RU";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";

const MapComponent = () => {
    useEffect(() => {
        let root = am5.Root.new("chartdiv");

        root.setThemes([
            am5themes_Dark.new(root),
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(am5map.MapChart.new(root, {
            panX: "translateX",
            panY: "translateY",
            wheelY: "none",
            pinchZoom: true,
            maxDeviation: 0.2,
            minZoomLevel: 1,
            maxZoomLevel: 12,
            projection: am5map.geoMercator(),
            background: am5.Rectangle.new(root, {
                fillGradient: am5.LinearGradient.new(root, {
                    stops: [{
                        color: am5.color("#1A1A1E")
                    }, {
                        color: am5.color("#1A1A1E")
                    }]
                })
            })
        }));

        // Define the data
        const locations = {
            'vienna': { country: 'Austria', city: 'Vienna', speed: '3GBIT', latitude: 48.2082, longitude: 16.3738 },
            'buenos_aires': { country: 'Argentina', city: 'Buenos Aires', speed: '2GBIT', latitude: -34.6037, longitude: -58.3816 },
            'sofia': { country: 'Bulgaria', city: 'Sofia', speed: '1GBIT', latitude: 42.6977, longitude: 23.3219 },
            'prague': { country: 'Czechia', city: 'Prague', speed: '1GBIT', latitude: 50.0755, longitude: 14.4378 },
            'copenhagen': { country: 'Denmark', city: 'Copenhagen', speed: '400MBIT', latitude: 55.6761, longitude: 12.5683 },
            'tallinn': { country: 'Estonia', city: 'Tallinn', speed: '1GBIT', latitude: 59.4370, longitude: 24.7536 },
            'helsinki': { country: 'Finland', city: 'Helsinki', speed: '1GBIT', latitude: 60.1699, longitude: 24.9384 },
            'frankfurt': { country: 'Germany', city: 'Frankfurt', speed: '4GBIT', latitude: 50.1109, longitude: 8.6821 },
            'budapest': { country: 'Hungary', city: 'Budapest', speed: '10GBIT', latitude: 47.4979, longitude: 19.0402 },
            'tokyo': { country: 'Japan', city: 'Tokyo', speed: '4GBIT', latitude: 35.6895, longitude: 139.6917 },
            'almaty': { country: 'Kazakhstan', city: 'Almaty', speed: '1gbit', latitude: 43.2220, longitude: 76.8512 },
            'vilnius': { country: 'Lithuania', city: 'Vilnius', speed: '1GBIT', latitude: 54.6872, longitude: 25.2797 },
            'amsterdam': { country: 'Netherlands', city: 'Amsterdam', speed: '10GBIT', latitude: 52.3676, longitude: 4.9041 },
            'oslo': { country: 'Norway', city: 'Oslo', speed: '1GBIT', latitude: 59.9139, longitude: 10.7522 },
            'warsaw': { country: 'Poland', city: 'Warsaw', speed: '10GBIT', latitude: 52.2297, longitude: 21.0122 },
            'timisoara': { country: 'Romania', city: 'Timisoara', speed: '1GBIT', latitude: 45.7489, longitude: 21.2087 },
            'belgrade': { country: 'Serbia', city: 'Belgrade', speed: '1GBIT', latitude: 44.7866, longitude: 20.4489 },
            'madrid': { country: 'Spain', city: 'Madrid', speed: '10GBIT', latitude: 40.4168, longitude: -3.7038 },
            'stockholm': { country: 'Sweden', city: 'Stockholm', speed: '1GBIT', latitude: 59.3293, longitude: 18.0686 },
            'zurich': { country: 'Switzerland', city: 'Zurich', speed: '1GBIT', latitude: 47.3769, longitude: 8.5417 },
            'istanbul': { country: 'Turkey', city: 'Istanbul', speed: '1GBIT', latitude: 41.0082, longitude: 28.9784 },
            'seattle': { country: 'USA', city: 'Seattle', speed: '1GBIT', latitude: 47.6062, longitude: -122.3321 },
            'new_york': { country: 'USA', city: 'New York', speed: '1GBIT', latitude: 40.7128, longitude: -74.0060 },
            'london': { country: 'UK', city: 'London', speed: '1GBIT', latitude: 51.5074, longitude: -0.1278 },
            'brussels': { country: 'Belgium', city: 'Brussels', speed: '1GBIT', latitude: 50.8503, longitude: 4.3517 },
            'bursa': { country: 'Turkey', city: 'Bursa [3]', speed: '1GBIT', latitude: 40.1828, longitude: 29.0665 },
            'lima': { country: 'Peru', city: 'Lima', speed: '500MBIT', latitude: -12.0464, longitude: -77.0428 },
            'hong_kong': { country: 'Hong Kong', city: 'Hong Kong', speed: '1GBIT', latitude: 22.3193, longitude: 114.1694 },
            'tbilisi': { country: 'Georgia', city: 'Tbilisi', speed: '1GBIT', latitude: 41.7151, longitude: 44.8271 }
        };

        // Create series for polygons
        let polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
            geoJSON: am5geodata_worldRussiaLow,
            geodataNames: am5geodata_lang_RU,
            exclude: ["AQ"]
        }));
        polygonSeries.mapPolygons.template.setAll({
            stroke: am5.color("#1a1a1a"),
            fill: am5.color("#4f4f4f"),
            strokeWidth: 1
        });

        // Create series for points/cities
        let citySeries = chart.series.push(am5map.MapPointSeries.new(root, {}));
        citySeries.bullets.push(function () {
            const circle = am5.Circle.new(root, {
                radius: 7,
                tooltipText: "{title}",
                tooltipY: 0,
                fill: am5.color("#38D68E"),
                stroke: am5.color("#38D68E"),
                strokeWidth: 30,
                strokeOpacity: 0.1
            });
            circle.states.create("hover", {
                fill: am5.color("#1a1a1a"),
                strokeWidth: 30,
                strokeOpacity: 0.1,
                stroke: am5.color("#38D68E") // Corrected property name
            });

            return am5.Bullet.new(root, {
                sprite: circle
            });
        });

          const cities = Object.keys(locations).map(key => {
            const location = locations[key];
            return {
                id: key,
                title: `[fontSize: 1rem]${location.country}, ${location.city}[/]\n[fontSize: 0.8rem] ${location.speed.replace('GBIT', ' Gbit/s').replace('MBIT', ' Mbit/s')}[/]`,
                geometry: {type: "Point", coordinates: [location.longitude, location.latitude]}
            }
        });
        citySeries.data.setAll(cities);


        chart.set("zoomControl", am5map.ZoomControl.new(root, {}));

        polygonSeries.events.on("datavalidated", function () {
            chart.zoomToGeoPoint({latitude: 50.0755, longitude: 14.4378}, 3.5);
        });




        return () => {
            root.dispose();
        };
    }, []);

    return <div id="chartdiv" style={{ width: "100%", height: "100vh" }}></div>;
};

export default MapComponent;